export const gearData = [
  {
    name: 'Eslinga Gear América',
    category: 'recovery',
    link: 'https://www.amazon.com.mx/gearamerica-remolque-correas-recuperaci%C3%B3n-Anaranjado/dp/B07BR4DJDF/ref=sr_1_5?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=511418BUJZC9&keywords=gear+america+eslinga&qid=1684079949&sprefix=gear+america+eling%2Caps%2C139&sr=8-5',
    thumbnail: 'eslinga.jpg'
  },
  {
    name: 'Grilletes',
    category: 'recovery',
    link: 'https://www.amazon.com.mx/GearAmerica-Parent-Shackles-Isolators-Red/dp/B07GJ7HYMY/ref=sr_1_1_sspa?keywords=grilletes+gear+america&qid=1684080105&sprefix=grilletes+gear%2Caps%2C134&sr=8-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEyWUFSVllIOENQOEVaJmVuY3J5cHRlZElkPUEwMDMzNjA4MUowUkhTS1NUU1NNSSZlbmNyeXB0ZWRBZElkPUEwOTYyNzQ0MVY4QTcxQlpWWDVXNyZ3aWRnZXROYW1lPXNwX2F0ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU=',
    thumbnail: 'grilletes.jpg'
  },
  {
    name: 'Bandas de sujecion',
    category: 'recovery',
    link: 'https://www.amazon.com.mx/Truper-1000C-Sujetadores-Matraca-1000/dp/B00UY1WPB2/ref=sr_1_12?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3DVA2KJWDH0YD&keywords=bandas+truper&qid=1684080214&sprefix=bandas+truper%2Caps%2C136&sr=8-12',
    thumbnail: 'bandas-sujecion.jpg'
  },
  {
    name: 'Cuerdas, piolas, sogas',
    category: 'recovery',
    link: 'https://www.homedepot.com.mx/ferreteria/ferreteria-general/cuerdas-y-mecates/cuerda-polipropileno-poliester-3048-m-776461?frame=1',
    thumbnail: 'cuerdas.jpg'
  },
  {
    name: 'winch 9500 libras',
    category: 'recovery',
    link: 'https://articulo.mercadolibre.com.mx/MLM-600853917-smittybilt-gen2-xrc-9500-lb-winch-jeep-97495-wrangler-funda-_JM#position=1&search_layout=stack&type=item&tracking_id=e4e74d09-57c8-460c-8d16-9d1bd183744e',
    thumbnail: 'winch.webp'
  },
  {
    name: 'Tienda de campaña CORE 10 personas',
    category: 'camping',
    link: 'https://www.costco.com.mx/Deportes/Campismo/Casas-de-Campana/Core-Tienda-de-Campana-Instantanea-con-Iluminacion/p/1654506',
    thumbnail: 'casa-core.webp'
  },
  {
    name: 'Tienda de campaña Nautika 4 personas',
    category: 'camping',
    link: 'https://www.walmart.com.mx/ip/camping/casa-de-campana-4-personas-columna-de-2500mm-nautika-tienda-de-campana-cherokee/00789655843430?from=searchResults',
    thumbnail: 'casa-nautika.jpg'
  },
  {
    name: 'Estufa portátil',
    category: 'camping',
    link: 'https://www.amazon.com.mx/Truper-ESPO-1-port%C3%A1til-encendido-electr%C3%B3nico/dp/B00UY259VE/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3GN0EQACCQ0VL&keywords=parrilla+truper&qid=1684080790&sprefix=parrila+truper%2Caps%2C142&sr=8-1',
    thumbnail: 'parrilla-truper.png'
  },
  {
    name: 'Radio Baofeng UV-5R',
    category: 'Communication',
    link: 'https://www.amazon.com.mx/Baofeng-UV-5R-136-174MHz-400-520MHz-Transceiver/dp/B074XPB313/ref=sr_1_1_sspa?keywords=baofeng+uv5r&qid=1684080964&sprefix=baofeng%2Caps%2C143&sr=8-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExSUlEQVIwVEw5U1cxJmVuY3J5cHRlZElkPUEwOTAzMjk2MzhDR1pJT1lDUVg1WSZlbmNyeXB0ZWRBZElkPUEwNTMzNDYzMVRMTTkwM1lFSzNBQSZ3aWRnZXROYW1lPXNwX2F0ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU=',
    thumbnail: 'baofeng.png'
  },
  {
    name: 'Radios Midland',
    category: 'Communication',
    link: 'https://www.sams.com.mx/telefonia-fija-y-radios-portatiles/set-de-radios-2-vias-midland-t5blk-2-pzas/981001851',
    thumbnail: 'midland.png'
  },
]