<template>
  <div class="TrailView">
    <section class="first-section">
      <div class="wrapper">
        <h1 class="trail-title">{{trail.name}}</h1>
        <h2 class="trail-description">{{trail.distance}}KM, {{trail.city}}</h2>
        <h2 v-show="trail.isPrivate"> {{trail.date }}</h2>
        <h2 class="trail-description">{{ trail.shortDescription }}</h2>
      </div>
    </section>
    <section class="teaser-section">
      <youtube :videoID="trail.youtubeId"></youtube>
    </section>
    <section class="second-section">
      <div class="wrapper">
        <a href="https://completa-tu-pago2.payclip.com/e6a23191-6c8c-4def-8c12-08ff0b2bc6fa"><img src="https://assets-global.website-files.com/62588b32d8d6105ab7aa9721/63bf568610f3fdf437235192_Preview.svg" alt="Logo Paga con Clip" />
        </a>
        <div v-html="trail.longDescription" class="custom-html">
        </div>
        <a href="https://completa-tu-pago2.payclip.com/e6a23191-6c8c-4def-8c12-08ff0b2bc6fa"><img src="https://assets-global.website-files.com/62588b32d8d6105ab7aa9721/63bf568610f3fdf437235192_Preview.svg" alt="Logo Paga con Clip" />
        </a>
      </div>
    </section>
    <section>
      <div class="grid">
        <div v-for="(image,index) in trail.images" class="grid-item" :style="{ backgroundImage: `url(${image})` }" :key="index">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { marked } from 'marked';
import { trailsData } from '../scripts/trails_data';
import Youtube from '@/components/Youtube'
import {db} from '@/main'
import {collection, query, getDocs, where } from "firebase/firestore";
export default {
  name: 'TrailView',
  components: {
    Youtube,
  },
  data(){
    return {
      trail: {
        name: '',
        images: [],
        distance: '',
        city: ''
      },
    }
  },
  created() {
    this.getData()
  },
  mounted() {
  },
  methods: {
   async getData() {
      const q = query(collection(db, "trails"),where("name", "==",this.$route.params.trailname));
      const querySnapshot = await getDocs(q).then((result)=> {        
        result.forEach((doc) => {

        const current = doc.data()
        this.trail.name = current.name
        this.trail.distance = current.distance
        this.trail.city = current.city
        this.trail.images = current.images
        this.trail.id = current.id
        this.trail.isPrivate = current.isPrivate
        this.trail.youtubeId = current.youtubeId
        this.trail.shortDescription = current.shortDescription
        this.trail.longDescription = marked.parse(current.longDescription, {
              headerIds: false,
              mangle: false
            })
        this.trail.date = current.date.split('-').reverse().join('/')

      })
      });
      // let trail =  {}
      
      // return trail
    }
  }
}
</script>
<style src="@/styles/views/TrailView.styl" lang="stylus" scoped></style>