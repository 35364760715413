import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDbbX7lE0-b0mSt3aTOuBqYE5n3h6CmmRw",
  authDomain: "elmalcamino-89df2.firebaseapp.com",
  projectId: "elmalcamino-89df2",
  storageBucket: "elmalcamino-89df2.appspot.com",
  messagingSenderId: "666807143186",
  appId: "1:666807143186:web:793a91a3b808224b01c1cb",
  measurementId: "G-6P9TQV3QB6"
};

// Initialize Firebase
export const fbapp = initializeApp(firebaseConfig);
const analytics = getAnalytics(fbapp);

export const db = getFirestore(fbapp);



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')





