<template>
  <div class="Home">
    <section class="first-section" :style="{ backgroundImage: `url(${require('@/assets/tj_main.jpg')})` }">
        <Logo logo-color="white" logo-width="250px"/>
        <cta @click.native="goToTrails()">
            Explora nuestras rutas
        </cta>
    </section>
    <section class="second-section">
      <div class="grid-half">
        <img class="me-vertical" src="../assets/mejeep.jpeg" alt="">
       <div>
        <p class="body-text">Hola yo soy Heri Rodriguez A.K.A. <span class="accent-color">Bisonte </span>
        </p>
        <p class="body-text">Hago videos en youtube sobre off road, overland y rutas todo terreno desde el Norte de México.
        </p> 
        <cta @click.native="goToAbout()">
          Más Acerca de MI
        </cta>
       </div>
      
      </div>
    </section>
    <section class="third-section">
      <h1 >Sigueme en instagram: <br> @elmalcaminooffroad</h1>
    </section>
    <section class="fourth-section">
      <div>
        <h1>Te invitamos a que sigas nuestras aventuras por youtube</h1>
        <youtube :videoID="'4txb4Mx93HM'"></youtube>
        <cta @click.native="suscribe()">
          Suscríbete
        </cta>

        <h1>Escribenos a través de nuestas redes sociales</h1>
        <div>
        <cta @click.native="instagram" class="rrss">Instagram</cta>
        <cta @click.native="facebook" class="rrss">Facebook</cta>
        <cta @click.native="youtube" class="rrss">Youtube</cta>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Logo from '@/components/Logo.vue'
import Cta from '@/components/Cta.vue'
import Youtube from '@/components/Youtube.vue'
import axios from 'axios'
export default {
  name: 'Home',
  components: {
    Logo,
    Cta,
    Youtube,
  },
  mounted() {

  },
  methods: {
    suscribe(){
      console.log('suscribe');
      const url = 'https://www.youtube.com/@ElMalCamino?sub_confirmation=1'
      window.open(url, "_blank");
    },
    goToAbout() {
      this.$router.push('/historia')
    },
    goToTrails() {
      this.$router.push('/rutas')
    },
    facebook() {
      window.open('https://www.facebook.com/malcaminooffroad', '_blank');
    },
    instagram() {
      window.open('https://www.instagram.com/elmalcaminooffroad/', '_blank');
    },
    youtube() {
      window.open('https://www.youtube.com/c/ElMalCamino/videos', '_blank');
    },

  }
}
</script>
<style src="@/styles/views/Home.styl" lang="stylus" scoped></style>