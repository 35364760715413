<template>
  <div class="Gear">
    <section class="gear-first">
      <div class="wrapper">
        <h1>En la vida como en <span class="color-accent">el mal camino</span>, contar con buenas <span class="color-accent">herramientas y equipo</span> hacen la diferencia. </h1>
      <h1>Entre mas preparado te encuentres durante tus viajes por la naturaleza, más fácil será <span class="color-accent">conquistarla.</span></h1>
        <h1>Este es el equipo que uso en <span class="color-accent">mis aventuras off road y overland</span>, espero te ayuden tanto como a mí</h1>
      </div>
    </section>
    <section class="gear-second">
      <div class="wrapper-full">
        <h1>Vehículos del Mal Camino</h1>
        <h2>Jeep Wrangler Rubicon 2008 Automático 4 Puertas</h2>
        <div class="grid-half">
          <div class="grid-item">
            <div class="bg-image" :style="{ backgroundImage: `url(${require('@/assets/jk1.jpg')})` }">
            </div>
          </div>
          <div class="grid-item">
            <div class="bg-image" :style="{ backgroundImage: `url(${require('@/assets/jk2.png')})` }">
            </div>
          </div>
          <div class="grid-item">
            <div class="bg-image" :style="{ backgroundImage: `url(${require('@/assets/jk3.png')})` }">
            </div>
          </div>
        </div>

        <h2>Jeep Wrangler Sport 2005 Manual 6 velocidades</h2>
        <div class="grid-half">
          <div class="grid-item">
            <div class="bg-image" :style="{ backgroundImage: `url(${require('@/assets/tj1.png')})` }">
            </div>
          </div>
          <div class="grid-item">
            <div class="bg-image" :style="{ backgroundImage: `url(${require('@/assets/tj2.png')})` }">
            </div>
          </div>
          <div class="grid-item">
            <div class="bg-image" :style="{ backgroundImage: `url(${require('@/assets/tj3.png')})` }">
            </div>
          </div>
        </div>

      </div>
    </section>

    <!-- <section class="gear-second">
      <div class="wrapper-full">
        <h1>Equipo de Camping / Overland / Off Road</h1>
        
        <div class="grid-half">
        
          <div class="grid-item" v-for="(item,index) in gearData" :key="index">
            <h2>{{ item.name }}</h2>
            <div class="bg-image" :style="{ backgroundImage: `url(${require(`@/assets/gearMalcamino/${item.thumbnail}`)})` }">
            </div>
            <h3>{{ item.category }}</h3>
            <a target="_blank" :href="item.link"> ver producto </a>
          </div>

        </div>

      </div>
    </section> -->
    <!-- <h1>Equipo de Camping / Overland</h1>
    <h1>Equipo de recuperación</h1>
    <h1>Comunicación todo terreno</h1>
    <h1>Navegación y comodidades</h1> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Logo from '@/components/Logo.vue'
import { gearData } from '../scripts/gear_data';
export default {
  name: 'Gear',
  components: {
    Logo
  },
  data() {
    return{
      gearData: null
    }
  },
  mounted() {
    this.gearData = gearData;
  },
}
</script>
<style src="@/styles/views/Gear.styl" lang="stylus" scoped></style>