<template>
  <div class="youtube-video">
    <iframe id="player" type="text/html" width="100%" height="100%"
    :src="`https://www.youtube.com/embed/${videoID}?enablejsapi=1&origin=https://elmalcamino.com`"
    frameborder="0">
  </iframe>
  </div>
</template>

<script>
export default {
  name: 'Youtube',
  props: {
    videoID: String
  }
}
</script>

<style src="@/styles/components/Youtube.styl" lang="stylus"></style>