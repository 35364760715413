<template>
  <div class="Trails">
    <section class="first-trails">
      <div class="wrapper">
        <h1><span class="color-accent">Convertirse en leyendas </span> es parte del mal camino, estas son las próximas  <span class="color-accent">rutas por conquistar.</span></h1>
      </div>
    </section>
    <section class="second-trails">
      <div class="grid">
        <div class="grid-item">
          <h1 class="grid-text">Próximas Rutas Privadas</h1>
        </div>
        <div class="grid-item" v-for="(trail, index) in privateTrails" :key="index">
          <router-link :to="{name:'trail', path:`/rutas/${trail.name}`, params: { trailname: trail.name, trailId: trail.id }}">
            <div class="bg-image" :style="{ backgroundImage: `url(${trail.thumbnail})` }">
              <div class="overlay">
              </div>
              <div class="trail-data">
                <h2>{{ trail.name }}</h2>
                <h2>{{ trail.location }}</h2>
                <h2>{{ trail.distance }}KM </h2>
                <h2>{{ formatDate(trail.date)}}</h2>
              </div>
            </div>
          </router-link>
          </div>
        </div>
    </section>
    <section class="first-trails">
      <div class="wrapper">
        <h1>Todas las rutas llevan al <span class="color-accent">mal camino</span>, estas son algunas que hemos <span class="color-accent">conquistado.</span></h1>
      </div>
    </section>
    <section class="second-trails">
      <div class="grid">
        <div class="grid-item">
          <h1 class="grid-text">Rutas abiertas en las que he participado</h1>
        </div>
         <div class="grid-item" v-for="(trail, index) in openTrails" :key="index">
          <router-link :to="{name:'trail', path:`/rutas/${trail.name}`, params: { trailname: trail.name, trailId: trail.id }}">
            <div class="bg-image" :style="{ backgroundImage: `url(${trail.thumbnail})` }">
              <div class="overlay">
              </div>
              <div class="trail-data">
                <h2>{{ trail.name }}</h2>
                <h2>{{ trail.location }}</h2>
                <h2>{{ trail.distance }}KM </h2>
              </div>
            </div>
          </router-link>
          </div>
        </div>
    </section>
  
  </div>
</template>

<script>
// @ is an alias to /src
// import axios from 'axios'
import Logo from '@/components/Logo.vue'
import { trailsData } from '../scripts/trails_data';
import {db} from '@/main'
import {collection, query, getDocs, where, orderBy } from "firebase/firestore";
import TrailView from './TrailView.vue';
// import { getStorage, ref,uploadBytes, getDownloadURL } from "firebase/storage";
export default {
  name: 'Trails',
  components: {
    Logo
  },
  async created() {
    this.privateTrails = []
    this.openTrails = []
    const q = query(collection(db, "trails"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      const current = doc.data()
      if(current.isPrivate) {
          this.privateTrails.push({
          name: current.name,
          location: current.city,
          distance: current.distance,
          date: current.date ? current.date : '',
          thumbnail: current.thumbnail,
          id: doc.id
        })
      } else {
        this.openTrails.push({
        name: current.name,
        location: current.city,
        distance: current.distance,
        thumbnail: current.thumbnail,
        id: doc.id
      })
      }
    });
  },
  data() {
    return {
      openTrails: [],
      privateTrails: []
    }
  },
  mounted(){
  //  this.trails = trailsData
  },
  methods: {
    formatDate(date) {
      return date.split('-').reverse().join('/')
    }
  }
}
</script>
<style src="@/styles/views/Trails.styl" lang="stylus" scoped></style>