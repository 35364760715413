<template>
  <div class="Admin">
    <h1>Create Trail</h1>
    <div class="form">
        <div class="input-wrapper">
        <label>name:</label>
        <input type="text" v-model="trail.name">
        </div>
        <div class="input-wrapper">
          <label>distance:</label>
        <input type="number" v-model="trail.distance">
        </div>
        <div class="input-wrapper">
          <label>city:</label>
        <input type="text" v-model="trail.city">
        </div>
        <div class="input-wrapper">
          <label>youtube id:</label>
        <input type="text" v-model="trail.youtubeId">
        </div>
      </div>
      <div class="form">
        <div class="input-wrapper">
        <label>es privada:</label>
        <input type="checkbox" v-model="trail.isPrivate">
        </div>
        <div class="input-wrapper" v-show="trail.isPrivate">
          <label>Fecha</label>
        <input type="date" v-model="trail.date">
        </div>
        <div class="input-wrapper">
          <label>location lat:</label>
        <input type="number" v-model="trail.location.lat">
        </div>
        <div class="input-wrapper">
          <label>location long:</label>
        <input type="number" v-model="trail.location.long">
        </div>
    </div>
    <div class="form">
        <div class="input-wrapper">
        <label>short description:</label>
        <textarea cols="80" rows="5" v-model="trail.shortDescription"></textarea>
        </div>
        <div class="input-wrapper">
        <label>long description:</label>
        <textarea cols="80" rows="10" v-model="trail.longDescription" @input="updateMD()">
        </textarea>
        <div ref="renderMarkdown" class="markdownview" v-html="compiledMarkdown">
        </div>
        </div>
    </div>
    <div class="form">
        <div class="input-wrapper">
          <label>thumbnail:</label>
        <input type="file" @change="createThumb()">
        <img class="prev" :src="`${tempImg}`" alt="">
        </div>
    </div><div class="form">
        <div class="input-wrapper">
          <label for="">images</label>
          <input @change="createFilesUpload()" type="file" multiple="multiple">
          <img v-for="(image, index) in images" class="prev" :src="`${image}`" alt="" :key="index">
        </div>
    </div>
    <br>
    <br>
    <div class="form">
      <cta @click.native="createTrail()"> create </cta>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import axios from 'axios'
import _ from 'lodash'
import { marked } from 'marked';
import Cta from '@/components/Cta'
import {db} from '@/main'
import { collection, addDoc } from "firebase/firestore";
import { getStorage, ref,uploadBytes, getDownloadURL } from "firebase/storage";
// import Compressor from 'compressorjs';
export default {
  name: 'Admin',
  components: {
    Cta
  },
  created() {
  },
  data() {
    return {
      trail: {
        name: 'El pino',
        distance: 12,
        location: {
          lat: 85,
          long: 110
        },
        thumbnail: '',
        images: [],
        isPrivate: false,
        date: '',
        shortDescription: '',
        longDescription: `## Título tentativo de la ruta

El viaje comienza en Saltillo, donde los aventureros pueden cargar gasolina y abastecerse de suministros necesarios para el viaje. Desde allí, la ruta "El Pino" te llevará a través de una serie de caminos de tierra y terrenos escarpados. Será una experiencia emocionante y desafiante, perfecta para aquellos que buscan la adrenalina de un viaje todo terreno.

A lo largo de la ruta, los aventureros podrán disfrutar de las hermosas vistas de los paisajes naturales de Coahuila. Verán montañas majestuosas, valles verdes, ríos cristalinos y mucho más. Además, tendrán la oportunidad de interactuar con la gente local y conocer la cultura y la historia de la región.
Finalmente, la ruta llega al ejido "El Pino", donde los aventureros pueden descansar y disfrutar de la tranquilidad de la naturaleza. Allí, pueden establecer campamento, cocinar una cena en una fogata y pasar la noche bajo las estrellas. En definitiva, esta ruta ofrece una experiencia única y emocionante que cualquier amante de las aventuras todo terreno no querrá perderse.

¡Prepárate para una experiencia inolvidable en la ruta "El Pino"!`,
        youtubeId: '',
        // createdAt : firebase.firestore.FieldValue.serverTimestamp()
      },
      tempImg: '',
      images: [],
      imagesFiles: [],
    }
  },
  mounted(){
  },
  computed: {
          compiledMarkdown: function() {
            return marked.parse(this.trail.longDescription, {
              headerIds: false,
              mangle: false
            });
          }
        },
  methods: {
    updateMD() {
      _.debounce(function(e) {
            this.trail.longDescription = e.target.value;
          }, 300)
    },
    async createTrail(){
      console.log('on create');
      await this.uploadThumb()
      this.uploadImages()
      
    },
    createThumb(){      
      const [file] = event.target.files
      if (file) {
        this.tempImg = URL.createObjectURL(file)
        this.thumbFile = file
      }
      
    },
    async uploadThumb() {
      const storage = getStorage();
      const storageRef = ref(storage, `/${this.trail.name}/thumbnail`);
      // 'file' comes from the Blob or File API
        uploadBytes(storageRef, this.thumbFile).then((snapshot) => {
        const urlPromise = getDownloadURL(ref(storage, storageRef)).then(url =>{
          this.trail.thumbnail = url
        })
      })
    },
    createFilesUpload(){
      const files = Array.from(event.target.files)
      files.forEach((file)=>{
        const imag = URL.createObjectURL(file)
        this.images.push(imag)
        this.imagesFiles.push(file)
      })
      
    },
   async uploadImages() {
      const storage = getStorage();
      const promises = []
      this.imagesFiles.forEach((image, index) => {
        const storageRef = ref(storage, `/${this.trail.name}/${this.trail.name}-${index}`);
        promises.push(uploadBytes(storageRef, image)
        .then((uploadResult) => {
          const urlPromise = getDownloadURL(uploadResult.ref)
          urlPromise.then((url)=>{
            this.trail.images.push(url)
          })          
          return urlPromise
        }))   
      })

      const photos = await Promise.all(promises);
      await console.log(photos);
      console.log('photos loaded');
      await addDoc(collection(db, "trails"), this.trail);
      console.log('trail upload');
    },
  }
}
</script>
<style src="@/styles/views/Admin.styl" lang="stylus" scoped></style>