<template>
  <div id="app">
    <Navbar></Navbar>
    <div class="router-view">
      <router-view/>
    </div>
    
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
export default {
  name: 'App',
  components: {
    Navbar
  }
}
</script>

<style src="@/styles/App.styl" lang="stylus">
</style>
