<template>
  <div class="LinkTree">
    <section class="first-section">
      <div class="centered-text">
        <h1 class="MainText">
      Bienvenido al <span class="accent-color">Mal Camino</span> te invito a que me sigas en mis redes sociales
      </h1>
    </div>
    </section>
    <section class="third-section">
      <div class="grid-half">
        <div>
        <cta @click.native="instagram" class="rrss">Instagram</cta>
        <cta @click.native="facebook" class="rrss">Facebook</cta>
        <cta @click.native="youtube" class="rrss">Youtube</cta>
        </div>
        <img class="me-vertical" src="../assets/tj3.png" alt="">
       <div>
        <p class="body-text">
          Gracias por formar parte de esta aventura y conquistar conmigo <span class="accent-color">El Mal Camino</span>
        </p> 
       </div>
      
      </div>
    </section>
    <!-- <section class="second-section">
      <div class="grid">
        <div class="grid-item" :style="{ backgroundImage: `url(${require('@/assets/jk1.jpg')})` }">
        </div>
        <div class="grid-item" :style="{ backgroundImage: `url(${require('@/assets/jk2.png')})` }">
        </div>
        <div class="grid-item" :style="{ backgroundImage: `url(${require('@/assets/jk3.png')})` }">
        </div>
        <div class="grid-item" :style="{ backgroundImage: `url(${require('@/assets/jk4.png')})` }">
        </div>
        <div class="grid-item" :style="{ backgroundImage: `url(${require('@/assets/tj1.png')})` }">
        </div>
        <div class="grid-item" :style="{ backgroundImage: `url(${require('@/assets/tj2.png')})` }">
        </div>
        <div class="grid-item" :style="{ backgroundImage: `url(${require('@/assets/tj3.png')})` }">
        </div>
        <div class="grid-item" :style="{ backgroundImage: `url(${require('@/assets/tj4.png')})` }">
        </div>
      </div>
    </section> -->
  </div>
</template>
<script>

import Cta from '@/components/Cta.vue'

export default {
  name: 'LinkTree',
  components: {
    Cta
  },
  props: {
    logoColor: String
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    facebook() {
      window.open('https://www.facebook.com/malcaminooffroad', '_blank');
    },
    instagram() {
      window.open('https://www.instagram.com/elmalcaminooffroad/', '_blank');
    },
    youtube() {
      window.open('https://www.youtube.com/c/ElMalCamino/videos', '_blank');
    },
  }
}
</script>
<style src="@/styles/views/LinkTree.styl" lang="stylus" scoped></style>