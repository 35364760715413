<template>
  <button class="Cta">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Cta',
  props: {
  }
}
</script>

<style src="@/styles/components/Cta.styl" lang="stylus"></style>