import { render, staticRenderFns } from "./TrailView.vue?vue&type=template&id=38846e45&scoped=true&"
import script from "./TrailView.vue?vue&type=script&lang=js&"
export * from "./TrailView.vue?vue&type=script&lang=js&"
import style0 from "@/styles/views/TrailView.styl?vue&type=style&index=0&id=38846e45&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38846e45",
  null
  
)

export default component.exports