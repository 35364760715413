import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Trails from '../views/Trails.vue'
import TrailView from '../views/TrailView.vue'
import Gear from '../views/Gear.vue'
import Admin from '../views/Admin.vue'
import Compressor from '../views/Compressor.vue'
import LinkTree from '../views/LinkTree.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/create-content',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/compressor',
    name: 'Compressor',
    component: Compressor
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/follow',
    name: 'LinkTree',
    component: LinkTree
  },
  {
    path: '/rutas',
    name: 'Trails',
    component: Trails,
  },
  {
    path: '/rutas/:trailname',
    name: 'trail',
    component: TrailView,
    props: route => ({
      trailname: route.params.trailname, // Pull `id` from route params
      trailId: route.params.trailId // Hard code `showProfilePicture`
    })
  },
  {
    path: '/equipamiento',
    name: 'Gear',
    component: Gear
  },
  {
    path: '/historia',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    window.scrollTo(0, 0);
    return { top: 0 }
  }
})


export default router
