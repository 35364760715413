<template>
  <div class="compressor">
    <input @change="createFilesUpload()" type="file" multiple="multiple">
    <img v-for="(image, index) in images" class="prev" :src="`${image}`" alt="" :key="index">
  </div>
</template>

<script>
// @ is an alias to /src
import Compressor from 'compressorjs';
export default {
  name: 'Compressor',
  data() {
    return {
      images: [],
      imagesFiles: []
    }
  },
  methods: {
     createFilesUpload(){
      const files = Array.from(event.target.files)
      files.forEach((file)=>{
        const imag = URL.createObjectURL(file)
        this.images.push(imag)
        this.imagesFiles.push(file)
      })
      console.log('init compressing');
      const blobFiles = []
      function downloadBlob(blob, name = 'file.txt') {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = blobUrl;
        link.download = name;
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent('click', { 
            bubbles: true, 
            cancelable: true, 
            view: window 
          })
        );
        document.body.removeChild(link);
      }
      files.forEach((file) => {
        console.log(file);
        new Compressor(file, {
          quality: 0.6,
          // The compression process is asynchronous,
          // which means you have to access the `result` in the `success` hook function.
          success(result) {
            // console.log(result);
            downloadBlob(result, result.name)
          },
          error(err) {
            console.log(err.message);
          },
        });
        
        // this.downloadBlob(fileBlob, fileBlob.name)
      })
      console.log(blobFiles);
    },
    downloadBlob(blob, name = 'file.txt') {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = blobUrl;
        link.download = name;
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent('click', { 
            bubbles: true, 
            cancelable: true, 
            view: window 
          })
        );
        document.body.removeChild(link);
      }

  }
}
</script>
<style src="@/styles/views/Admin.styl" lang="stylus" scoped></style>